import { Outlet } from '@remix-run/react';

function AIChatRoute() {
  return (
    <div className='w-full h-full flex flex-col text-white px-10 pb-10'>
      <Outlet />
    </div>
  );
}

export const Component = AIChatRoute;

export function clientLoader() {
  return null;
}
